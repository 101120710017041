import { FAQ } from '~/types/data-array'

const RedesignFAQ: FAQ[] = [
  {
    question: 'What are website redesign services?',
    answer:
      'Website redesign services refer to the professional services provided by web designers and developers to enhance and update an existing solution. These services focus on improving a website’s visual appeal, functionality, user experience, and overall performance.',
  },
  {
    question: 'How to redesign a website?',
    answer:
      'Define the business goals and needs of users. Plan the site structure and layout. Then, design elements. Create or update content. Develop or update functionality. Conduct testing for usability and functionality and launch it. Remember to promote and monitor the performance of the redesigned site.',
  },
  {
    question: 'How much does it cost to redesign a website?',
    answer:
      'The website redesign cost is highly personalized and contingent upon the initial consideration of the project’s scope. Usually, the cost proposal can range from $21,200 to $75,000.',
  },
  {
    question: 'Why do I need to redesign my site?',
    answer:
      'Factors such as outdated design, complex navigation, and poor user experience can have a negative impact on your solution. It is highly advisable to regularly incorporate redesigning efforts to avoid potential losses in traffic and sales.',
  },
  {
    question: 'How often should you redesign your website?',
    answer:
      'Given the rapid development of modern technologies and the emergence of new trends, we highly recommend implementing a comprehensive website redesign every two to three years. Additionally, making regular incremental improvements is crucial to ensure your solution remains up-to-date.',
  },
]

export default RedesignFAQ
