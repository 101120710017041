// extracted by mini-css-extract-plugin
export var websiteRedesignCardBusinessSection = "q_bN";
export var websiteRedesignCaseSection = "q_bX";
export var websiteRedesignExpertiseSection = "q_bS";
export var websiteRedesignIndustriesSection = "q_bP";
export var websiteRedesignPracticesSection = "q_bV";
export var websiteRedesignPreferencesSection = "q_bR";
export var websiteRedesignPrimeSection = "q_bL";
export var websiteRedesignProcessSection = "q_bQ";
export var websiteRedesignServicesSection = "q_bM";
export var websiteRedesignTestimonialPost = "q_bT";
export var websiteRedesignWebDesignCasesSection = "q_bW";