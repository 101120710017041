import { useInView } from 'react-intersection-observer'

import logoDribble from '~/assets/images/services/website-redesign/logo-dribbble.svg'
import * as containerStylesButtons from '~/components/buttons/Button/Button.module.scss'
import { cx } from '~/utils'

import * as containerStyles from './ServicesCaseDesignSection.module.scss'

const ServicesCaseDesignSection = () => {
  const { inView, ref } = useInView({ rootMargin: '400px', triggerOnce: true })

  return (
    <div className="container container-md">
      <div className="row">
        <div className="col-12">
          <div
            ref={ref}
            className={cx(containerStyles.servicesCaseDesignSection, {
              [containerStyles.servicesCaseDesignSection__lazy]: inView,
            })}
          >
            <div className={containerStyles.servicesCaseDesignSection__wrapper}>
              <img
                src={logoDribble}
                width="116"
                height="29"
                alt="Logo Dribbble | Codica"
                title="Logo Dribbble"
                loading="lazy"
              />
              <div
                className={containerStyles.servicesCaseDesignSection__titleBox}
              >
                <div
                  className={
                    containerStyles.servicesCaseDesignSection__borderTop
                  }
                />
                <div
                  className={containerStyles.servicesCaseDesignSection__title}
                >
                  We create software that <span>rocks</span>
                </div>
                <div
                  className={
                    containerStyles.servicesCaseDesignSection__borderBottom
                  }
                />
              </div>
              <a
                href="https://dribbble.com/codica"
                target="_blank"
                className={cx(
                  containerStylesButtons.button,
                  containerStylesButtons.button__red,
                )}
              >
                Explore our Dribbble
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServicesCaseDesignSection
