import { ServicesProcessMock } from '~/types/mock'

const RedesignProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: 'Project discovery',
    descr:
      'We carry out a thorough audit to identify areas of improvement and create a project plan for site redesign. Our designers then create a prototype based on the website redesign strategy.',
  },
  {
    num: '02',
    title: 'Website visualization',
    descr:
      'Our talented designers focus on crafting the interface design. This stage involves implementing the visual components of the website and bringing it to life.',
  },
  {
    num: '03',
    title: 'Development',
    descr:
      'Building upon the visual representation created by our designers, our developers commence the implementation of the solution, bringing the envisioned ideas to reality.',
  },
  {
    num: '04',
    title: 'Content integration',
    descr:
      'Our team of specialists adds engaging visual content to the website, ensuring the essence of your message effectively reaches your target audience.',
  },
  {
    num: '05',
    title: 'Testing',
    descr:
      'Our skilled quality assurance experts meticulously test the website to ensure seamless functionality and user-friendliness, guaranteeing a smooth browsing experience.',
  },
]

export default RedesignProcess
