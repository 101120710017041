import { ExpertiseSectionMock } from '~/types/mock'

const RedesignServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_12',
    title: 'UX and UI redesign',
    link: '/services/ui-ux-design/',
    description:
      'With our UI and UX redesign services, we will enhance the digital product’s usability, functionality, and visual appeal, ensuring an intuitive user journey.',
  },
  {
    icon: 'sprite3DIcon_4',
    title: 'Custom redesign',
    description:
      'Turn to our website redesign company to get a unique design solution that reflects your brand identity and caters to your target audience.',
  },
  {
    icon: 'sprite3DIcon_5',
    title: 'Redesign website for mobile',
    description:
      'Mobile website redesign involves adapting the interface, navigation, and content presentation to deliver a mobile-friendly user experience.',
  },
  {
    icon: 'sprite3DIcon_14',
    title: 'B2B website redesign',
    description:
      'This service aims to enhance the design and functionality of a B2B website to attract and engage potential business clients.',
  },
  {
    icon: 'sprite3DIcon_18',
    title: 'B2C website redesign',
    description:
      'The overall effect of the quality website revamp will be a real boost for your B2C business by enhancing the user experience and optimizing the functionality.',
  },
  {
    icon: 'sprite3DIcon_2',
    title: 'C2C website redesign',
    description:
      'With our expertise in C2C website redesign, we can create an enjoyable and trustworthy online environment that empowers users to engage seamlessly.',
  },
]

export default RedesignServices
