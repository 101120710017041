import { ExpertiseSectionMock } from '~/types/mock'

const RedesignBestPractices: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_56',
    title: 'Understand the target audience',
    titleWidth: 'maxWidth_240',
    description:
      'Conducting research to deeply understand your target audience helps us tailor the redesign to cater to their specific needs.',
  },
  {
    icon: 'spriteIcon_73',
    title: 'Streamline navigation for easy exploration',
    titleWidth: 'maxWidth_240',
    description:
      'We make your website’s navigation user-friendly and intuitive so visitors find information quickly and effortlessly.',
  },
  {
    icon: 'spriteIcon_70',
    title: 'Maintain brand consistency',
    titleWidth: 'maxWidth_240',
    description:
      'A visually consistent and attractive website we create will help establish credibility and leave a positive impression on users.',
  },
  {
    icon: 'spriteIcon_66',
    title: 'Prioritize fast page loading',
    titleWidth: 'maxWidth_240',
    description:
      'We optimize your site’s performance by minimizing page loading times to ensure a seamless and fast browsing experience.',
  },
  {
    icon: 'spriteIcon_44',
    title: 'Implement conversion optimization',
    titleWidth: 'maxWidth_240',
    description:
      'We add clear call-to-action buttons throughout your site to foster users to take actions and maximize conversions.',
  },
  {
    icon: 'spriteIcon_74',
    title: 'Plan for regular updates',
    titleWidth: 'maxWidth_240',
    description:
      'We constantly monitor analytics to track performance and make necessary corrections to optimize the user experience.',
  },
]

export default RedesignBestPractices
