import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useWebsiteRedesignStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      portfolioTradeaboat: file(
        relativePath: { eq: "homepage/discover-works-portfolio-9-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      quoteCodicaInessaPhoto: file(relativePath: { eq: "avatars/Inessa.png" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default useWebsiteRedesignStaticQuery
