import { ExpertiseSectionMock } from '~/types/mock'

const RedesignBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_22',
    title: 'Site rebranding',
    description:
      'Establishing a cohesive visual identity throughout your website is crucial to building brand credibility and trust.',
  },
  {
    icon: 'spriteIcon_34',
    title: 'Boosted site traffic',
    description:
      'Drive more visitors to your website through effective marketing strategies and optimization techniques.',
  },
  {
    icon: 'spriteIcon_61',
    title: 'Mobile optimization',
    description:
      'Optimize your website for mobile visitors, ensuring it is fully responsive and can adapt to diverse screen sizes of devices.',
  },
  {
    icon: 'spriteIcon_65',
    title: 'Improved visitor-to-lead conversions',
    description:
      'Enhance the chances of turning website visitors into valuable leads through targeted and persuasive content.',
  },
  {
    icon: 'spriteIcon_33',
    title: 'Expanded site functionality',
    description:
      'Enhance your website’s capabilities by adding new features, tools, or functionalities that improve user experience and engagement.',
  },
]

export default RedesignBenefits
