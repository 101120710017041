import { ExpertiseSectionMock } from '~/types/mock'

const RedesignPreferences: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_43',
    title: 'Improved user experience',
    description:
      'Refreshing a site helps streamline its structure. We will enable the emphasis on necessary actions using top redesign tools and practices.',
  },
  {
    icon: 'spriteIcon_69',
    title: 'Upgraded functionality',
    description:
      'Website redesigns involve enhancing and expanding functionality, focusing on user-friendly and intuitive navigation.',
  },
  {
    icon: 'spriteIcon_78',
    title: 'Enhanced search engine optimization (SEO)',
    description:
      'Effective SEO optimization aims to enhance a website’s ranking in search engine results. We offer effective SEO strategies.',
  },
  {
    icon: 'spriteIcon_15',
    title: 'Faster page load times',
    description:
      'Website redesigns can optimize code and improve server response times, leading to faster page loading that impacts user experience.',
  },
  {
    icon: 'spriteIcon_25',
    title: 'Responsiveness across devices',
    description:
      'Websites need to adapt to distinct screen sizes and functionalities. At Codica, we ensure a website is tested for responsiveness.',
  },
]

export default RedesignPreferences
