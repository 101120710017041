import { getImage, StaticImage } from 'gatsby-plugin-image'

import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import Testimonial from '~/components/blocks/TestimonialPost'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import DiscoverWorksItem from '~/components/items/DiscoverWorksItem'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import SubtractBlock from '~/components/shared/SubtractBlock'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/website-redesign/Anchors'
import RedesignBenefits from '~/mock/services/website-redesign/RedesignBenefits'
import RedesignBestPractices from '~/mock/services/website-redesign/RedesignBestPractices'
import RedesignExperience from '~/mock/services/website-redesign/RedesignExperience'
import RedesignExpertise from '~/mock/services/website-redesign/RedesignExpertise'
import RedesignFAQ from '~/mock/services/website-redesign/RedesignFAQ'
import RedesignPreferences from '~/mock/services/website-redesign/RedesignPreferences'
import RedesignProcess from '~/mock/services/website-redesign/RedesignProcess'
import RedesignServices from '~/mock/services/website-redesign/RedesignServices'
import { fileToImageLikeData } from '~/utils'
import ServicesCaseDesignSection from '~/views/Services/components/ServicesCaseDesignSection'
import ServicesDevExpertiseCompany from '~/views/Services/components/ServicesDevExpertiseCompany'
import ServicesProcess from '~/views/Services/components/ServicesProcess'
import useWebsiteRedesignStaticQuery from '~/views/Services/WebsiteRedesign/useWebsiteRedesignStaticQuery'

import * as containerStyles from './WebsiteRedesign.module.scss'

const clientName = 'Celso Prado'

const WebsiteRedesignServices = () => {
  const query = useWebsiteRedesignStaticQuery()
  const portfolioTradeaboat = getImage(
    fileToImageLikeData(query.portfolioTradeaboat),
  )
  const quoteCodicaInessaPhoto = getImage(
    fileToImageLikeData(query.quoteCodicaInessaPhoto),
  )

  return (
    <MainLayout scrollTop>
      <section className={containerStyles.websiteRedesignPrimeSection}>
        <PrimeSectionServices
          titleTop="Website Redesign"
          title="Services"
          description="With website redesign services at Codica, you get not only a stunning visual look but also increased conversions. At our website redesign company, we will create a solution that elevates your online presence to a higher level."
          breadcrumbSecondLevel="Services"
          breadcrumbSecondLevelPath="/services/"
          breadcrumbThirdLevel="UI/UX Design Services"
          breadcrumbThirdLevelPath="/services/ui-ux-design/"
          breadcrumbCurrent="Website Redesign Services"
          breadcrumbCurrentPath="/services/ui-ux-design/website-redesign-services/"
        />
      </section>

      <StickyNavigation anchors={Anchors} />

      <section
        id="services"
        className={containerStyles.websiteRedesignServicesSection}
      >
        <HeadSection
          position="center"
          title="Website redesign service we offer"
          description="With website redesign services at Codica, you get not only a stunning visual look but also increased conversions. At our website redesign company, we will create a solution that elevates your online presence to a higher level."
          descriptionLarge
        />
        <Service3DIconCard dataContent={RedesignServices} />
      </section>

      <section className={containerStyles.websiteRedesignCardBusinessSection}>
        <HeadSection
          position="center"
          title="Redesign the website for your business needs"
          description="Your business aims will define the scale of your website redesign. But you definitely should redesign your site if you need:"
          descriptionLarge
        />
        <ServicesIconSmallCard isLeft dataContent={RedesignBenefits} />
        <SubtractBlock />
      </section>

      <CallToAction
        title="Need a quality website redesign?"
        subtitle="Our experts will create it for you."
        classNameForGA="servicesCTA_1"
        linkName="Contact us"
        link="/contacts/"
      />

      <section
        id="industries"
        className={containerStyles.websiteRedesignIndustriesSection}
      >
        <HeadSection
          position="center"
          title="Industries we serve"
          description="Every website should reflect the uniqueness of the company it represents. With that in mind, Codica presents a diverse selection of website redesign services tailored to various types of sites of different industries. We will refresh your solution effectively and within the website redesign budget."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={RedesignExpertise} />
      </section>

      <section
        id="process"
        className={containerStyles.websiteRedesignProcessSection}
      >
        <HeadSection
          position="center"
          title="Website redesign process"
          description="During 8 years of work, the Codica website redesign agency brought many pros for our clients’ companies that redesigned their solutions with us. Our process of effective website modernization includes the following website redesign steps."
          titleLarge
          descriptionLarge
        />
        <ServicesProcess dataContent={RedesignProcess} />
      </section>

      <section
        id="benefits"
        className={containerStyles.websiteRedesignPreferencesSection}
      >
        <HeadSection
          position="center"
          title="Benefits of website redesign services"
          description="A professional website redesign can significantly enhance the value of a business's online presence and offer numerous advantages."
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard isBorder dataContent={RedesignPreferences} />
      </section>

      <CallToAction
        title="Do you need a quality redesign for your solution?"
        subtitle="Our expert team will help you."
        classNameForGA="servicesCTA_2"
        linkName="Contact us"
        link="/contacts/"
      />

      <section className={containerStyles.websiteRedesignExpertiseSection}>
        <HeadSection
          position="center"
          title="Codica’s experience"
          description="Codica is an experienced partner in the industry regarding website redesign services. We know that what is good for users is good for business. So, our central idea is that the most effective way to redesign a website is by approaching it with end-users in mind. Applying our expertise, we conduct thorough research on your target audience, fill the website with relevant content, optimize it, test it, and provide ongoing support."
          descriptionLarge
        />
        <ServicesDevExpertiseCompany dataContent={RedesignExperience} />
      </section>

      <div className={containerStyles.websiteRedesignTestimonialPost}>
        <Testimonial
          image={quoteCodicaInessaPhoto}
          name="Inessa"
          position="UI/UX Lead at Codica"
          quote={[
            {
              text: '“Each project is a new challenge of how to make a beautiful and user-friendly interface. There is much work, analysis, and reflection behind the design screens in Figma. Research, logic creating, making prototypes, and visual style are the stages that we spend a lot of time on to get a good product.',
            },
            {
              text: 'We love what we do and improving our skills all the time.”',
            },
          ]}
        />
      </div>

      <section
        id="practices"
        className={containerStyles.websiteRedesignPracticesSection}
      >
        <HeadSection
          position="center"
          title="Best practices for the website redesign"
          description="At Codica, we consider these concise best practices for quality website enhancement:"
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard dataContent={RedesignBestPractices} />
      </section>

      <section
        id="cases"
        className={containerStyles.websiteRedesignWebDesignCasesSection}
      >
        <HeadSection
          position="center"
          title="Inspiring Web Design Cases"
          description="Explore Codica’s web design case studies to see how we redesign web solutions that look modern, perform great, and drive results."
          descriptionLarge
        />
        <ServicesCaseDesignSection />
      </section>

      <section className={containerStyles.websiteRedesignCaseSection}>
        <HeadSection position="center" title="Website redesign case study" />
        <DiscoverWorksItem
          key={3}
          gradient="gradientTradeaboat"
          image={portfolioTradeaboat}
          header="Redesign for the boat marketplace"
          title="This Australian online platform facilitates the buying and selling of new or pre-owned boats and marine equipment. In addition, visitors to the site can explore engaging and educational content related to boat sales."
          resultsLeftTitle="480%"
          resultsLeft="increase in lead generation performance"
          resultsRightTitle="null"
          resultsRight="Modern, robust, and fast-loading solution"
          link="online-marketplace-for-boats"
          review={[
            '“One of the main projects I took on when accepting this role was to redevelop two of the business’s various websites. The Codica team seemed to have a clear process from the beginning.',
            <div key="featuredCase" style={{ paddingTop: 10 }} />,
            'We are in verticals where market leaders have hundreds of in-house developers and multi-billion dollars in annual revenue. And despite all of that, our lean team, with the help from Codica, is growing its market share year after year. They are like my secret weapon.”',
          ]}
          clientPhoto={
            <StaticImage
              src="../../../assets/images/avatars/clients/client-tradervs.png"
              alt={`${clientName} | Codica`}
              title={clientName}
              width={50}
              height={50}
            />
          }
          clientName={clientName}
          clientPosition="Digital General Manager at the Adventures Group"
        />
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s build a dream web app solution together!"
          widthTitle="650px"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our web app specialists will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={RedesignFAQ} />
      </section>
    </MainLayout>
  )
}

export default WebsiteRedesignServices
