import { HomeSpecification } from '~/types/mock'

const RedesignExpertise: HomeSpecification[] = [
  {
    icon: 'sprite3DIconSmall_10',
    title: 'Online marketplaces',
    description:
      'We improve the overall aesthetics, navigation, and functionality of the marketplace to enhance the buying and selling experience.',
    link: '/case-studies/kids-activities-service-marketplace/',
  },
  {
    icon: 'sprite3DIconSmall_13',
    title: 'eCommerce',
    description:
      'We will create an engaging and user-friendly e-commerce platform that drives conversions and maximizes sales.',
    link: '/case-studies/custom-multi-vendor-marketplace-platform/',
  },
  {
    icon: 'sprite3DIconSmall_14',
    title: 'Travel',
    description:
      'At Codica, we make visually appealing and seamless travel online solutions that inspire users and facilitate travel planning.',
    link: '/case-studies/travel-management-platform/',
  },
  {
    icon: 'sprite3DIconSmall_15',
    title: 'Automotive',
    description:
      'Our experts will redesign online solutions in the automotive industry to enhance the user experience and streamline automotive-related processes.',
    link: '/services/automotive-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_4',
    title: 'Insurance',
    description:
      'Our skilled experts create efficient insurance online solutions that enable users to navigate through the complexities of insurance easily.',
    link: '/services/insurance-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_11',
    title: 'Recruiting',
    description:
      'With us, you will get an efficient and user-friendly interface that improves the overall recruitment experience for both employers and job seekers.',
    link: '/case-studies/recruitment-progressive-web-application/',
  },
  {
    icon: 'sprite3DIconSmall_16',
    title: 'Rental',
    description:
      'We redesign your online rental solution to make all interactions between renters and owners seamless, boosting trust and transparency.',
    link: '/case-studies/accommodation-search-website-mvp/',
  },
  {
    icon: 'sprite3DIconSmall_17',
    title: 'Media',
    description:
      'You will get a visually appealing media online solution that attracts and retains users and delivers an enjoyable and informative media experience.',
    link: '/case-studies/news-aggregator/',
  },
  {
    icon: 'sprite3DIconSmall_18',
    title: 'Healthcare',
    description:
      'We will redesign healthcare software that empowers patients, facilitates healthcare-provider interactions, and enhances overall healthcare experiences.',
    link: '/case-studies/dental-imaging-management-system/',
  },
]

export default RedesignExpertise
